<template>
  <div class="wrapper">
    <!-- テーブルAREA -->
    <CCard class="p-0 m-0 form-card" >
      <CCardHeader>
        <label class="header-title">患者登録・編集{{isCollapsed ?"(一覧モード)":"(登録モード)"}}</label>
        <div class="card-header-actions">
          <CButton color="primary" size="sm" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
        </div>

      </CCardHeader>
      <CCardBody class="p-0 my-0">
          <CCollapse :show="!isCollapsed" :duration="200" @finish="handleResize">
            <CCard class="p-0 my-0">
              <CCardBody class="py-1">
                <CRow>
                  <CCol col="6">
                    <CInput class="my-1"  horizontal label="カルテID" v-model="formData.karuteNo" addLabelClasses="required">
                    </CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="姓(全角)" v-model="formData.lastName" addLabelClasses="required"/>
                  </CCol>
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="名(全角)" v-model="formData.firstName" addLabelClasses="required"/>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="セイ(全角)" v-model="formData.lastNameKana" addLabelClasses="required"/>
                  </CCol>
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="メイ(全角)" v-model="formData.firstNameKana" addLabelClasses="required"/>
                  </CCol>
                </CRow>
                <CRow class="mt-0">
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="医療機関" v-model="hospitalName" readonly
                            @focus="hospitalWithDcotorSelect" addLabelClasses="required"/>
                  </CCol>
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="販売店" v-model="agencyName" readonly
                            @focus="agencyBranchSelect" addLabelClasses="required"/>
                  </CCol>
                </CRow>
                <CRow class="mt-0">
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="処方医" v-model="doctorName" readonly
                            @focus="hospitalWithDcotorSelect" addLabelClasses="required"/>
                  </CCol>

                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="販売店事業所" v-model="agencyBranchName" readonly
                            @focus="agencyBranchSelect" addLabelClasses="required"/>
                  </CCol>
                </CRow>
                <CRow class="mt-0">
                  <div class="col-6">
                    <div role="group" class="mb-1 form-group form-row">
                      <label for="prefectureId" class="col-sm-3">都道府県</label>
                      <div class="col-sm-9">
                        <v-select
                            id="prefectureId"
                            v-model="formData.prefectureId"
                            :options="prefectures"
                            key="prefectureId"
                            label="prefectureName"
                            :reduce="prefectures => prefectures.prefectureId"
                            >
                          <template slot="no-options">データがありません。</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div role="group" class="mt-0 mb-1  form-row">
                      <label for="birthDay" class="col-sm-3">生年月日</label>
                      <div class="col-sm-9 mb-0">
                        <datepicker id="birthDay" :value="formData.birthDay" :language="ja"
                                    v-model="formData.birthDay" format="yyyy/MM/dd" bootstrap-styling
                                    typeable placeholder="2020/01/01(半角)形式または日付を選択してください。"/>
                      </div>
                    </div>
                  </div>
                </CRow>
                <CRow class="mt-0">
                  <CCol col="6">
                    <CInput class="mb-1" horizontal label="市区町村" v-model="formData.city"/>
                  </CCol>
                  <CCol col="6">
                    <div role="group" class="mb-1 form-group form-row ">
                      <label for="genderM" class="col-sm-3">性別</label>
                      <div class="col-sm-9 mt-auto mb-auto">
                        <input id="genderM" type="radio" v-model="formData.gender" value="M">
                        <label for="genderM">男</label>

                        <input id="genderW" class="ml-4" type="radio" v-model="formData.gender" value="W">
                        <label for="genderW">女</label>

                        <input id="genderO" class="ml-4" type="radio" v-model="formData.gender" value="O">
                        <label for="genderO">その他</label>
                      </div>
                    </div>
                  </CCol>

                </CRow>
                <CRow class="mt-0">
                  <CCol col="6">
                    <CInput class="mb-1"  horizontal label="連絡先電話番号" @keypress="isNumber($event)" v-model="formData.tel"/>
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter v-if="editableFlg">
                <SaveButtons :id="formData.id" @formInit="formInit" @save="save" :show-save-btn="!isRegistrator()">
                  <template v-if="isAdministrator() || isRegistrator()" #right>
                    <CButton color="info" size="sm" class="ml-2 pull-right" @click="showDiviceClick" :disabled="!formData.id">設置機器登録</CButton>
                  </template>
                </SaveButtons>
              </CCardFooter>
            </CCard>
          </CCollapse>
      </CCardBody>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template slot="tools">
            <CButton v-if="isProvider()" size="sm" color="warning mr-1"  variant="outline" :pressed="allBranchFlg" @click="toggleAllBranch">全事業所
            </CButton>
          </template>

        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="hospitalNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>


          <template slot-scope="{ column,index }" slot="karuteNoHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="ageHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="genderHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>

          <template slot-scope="{ column,index }" slot="doctorNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>

          <!-- cell -->
          <template slot-scope="{ row,index }" slot="patientId"><div class="text-left">{{row.patientId}}</div></template>
          <template slot-scope="{ row,index }" slot="hospitalName"><div class="text-left">{{row.hospitalName}}</div></template>



          <template slot-scope="{ row,index }" slot="karuteNo"><div class="text-left">{{row.karuteNo}}</div></template>
          <template slot-scope="{ row,index }" slot="name"><div class="text-left">{{row.name}}</div></template>
          <template slot-scope="{ row,index }" slot="age"><div class="text-center">{{row.age}}</div></template>
          <template slot-scope="{ row,index }" slot="gender"><div class="text-center">{{row.gender}}</div></template>

          <template slot-scope="{ row,index }" slot="doctorName"><div class="text-left">{{row.doctorName}}</div></template>
          <template slot-scope="{ row,index }" slot="agencyName"><div class="text-left">{{row.agencyName}}</div></template>
          <template slot-scope="{ row,index }" slot="agencyBranchName"><div class="text-left">{{row.agencyBranchName}}</div></template>
          <template slot-scope="{ row,index }" slot="created"><div class="text-left">{{row.created}}</div></template>
          <template slot-scope="{ row,index }" slot="updated"><div class="text-left">{{row.updated}}</div></template>

          <template slot-scope="{ row,index }" slot="after">
            <div class="text-center">
              <template v-if="isProvider()" >
                <CButton v-if="row.registPatientFlg" color="info" size="sm" class="mr-1" @click="updateUser(row)">編集</CButton>
                <CButton v-else color="dark" size="sm" class="mr-1" disabled>編集</CButton>
              </template>
              <CButton v-else color="info" size="sm" class="mr-1" @click="updateUser(row)">編集</CButton>
            </div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>

    <!--プロバイダの事業所選択用-->
    <CModal :show.sync="showAgencyModal"
            size="xl"
            :no-close-on-backdrop="true"
            :centered="true"
            title="販売店&事業所">
      <CCard class="mt-1">
        <CCardBody>

          <AgencyWithBranch
              @setAgencyWithBranch="setAgencyWithBranch" :is-show="showAgencyModal"></AgencyWithBranch>
        </CCardBody>
      </CCard>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <CModal :show.sync="showHospitalWithDoctorModal"
            size="xl"
            :no-close-on-backdrop="true"
            :centered="true"
            title="医療機関&処方医">
      <CCard class="mt-1">
        <CCardBody>
          <HospitalWithDoctor  @setHospitalWithDoctor="setHospitalWithDoctor" :is-show="showHospitalWithDoctorModal"></HospitalWithDoctor>
        </CCardBody>
      </CCard>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <CModal :show.sync="showDivice"
            size="xl"
            :no-close-on-backdrop="true"
            :centered="true"
            title="設置機器登録">
        <CCard class="mt-1">
          <CCardHeader>
            <CRow>
              <CCol col="7">
                <CInput class="mt-0" size="sm" horizontal label="ゲートウェイSN" v-model="deviceFormData.gatewaySerialNumber" disabled/>
              </CCol>
              <CCol col="5">
                <CInput class="mt-0" size="sm" horizontal label="型番" v-model="deviceFormData.gatewayModelNumber" disabled >
                  <template #append><CButton color="info" @click="deviceClear('gateway')">解除</CButton></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="7">
                <CInput class="mt-0" size="sm" horizontal label="酸素濃縮装置SN" v-model="deviceFormData.oxSerialNumber" disabled />
              </CCol>
              <CCol col="5">
                <CInput class="mt-0" size="sm" horizontal label="型番" v-model="deviceFormData.oxModelNumber" disabled >
                  <template #append><CButton color="info" @click="deviceClear('ox')">解除</CButton></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="7">
                <CInput class="mt-0" size="sm" horizontal label="パルスオキシメータSN" v-model="deviceFormData.puSerialNumber" disabled />
              </CCol>
              <CCol col="5">
                <CInput class="mt-0" size="sm" horizontal label="型番" v-model="deviceFormData.puModelNumber" disabled >
                  <template #append><CButton color="info" @click="deviceClear('pu')">解除</CButton></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="7">
                <CInput class="mt-0" size="sm" horizontal label="血圧計SN" v-model="deviceFormData.blSerialNumber" disabled />
              </CCol>
              <CCol col="5">
                <CInput class="mt-0" size="sm" horizontal label="型番" v-model="deviceFormData.blModelNumber" disabled >
                  <template #append><CButton color="info" @click="deviceClear('bl')">解除</CButton></template>
                </CInput>
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody class="text-right">
            <CButton size="sm" color="danger" @click="deviceSelectSave">登録</CButton>
          </CCardBody>
          <CCardFooter>
            <Device v-if="isAdministrator() || isRegistrator()" ref="device"  :show-lost-discard="false" :show-download="false"
                    :is-collapsed-nested="isCollapsed" device-select-flg :show-device-select="true" @deviceSelect="deviceSelect" ></Device>

          </CCardFooter>
        </CCard>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>

</template>

<script>
import AgencyWithBranch from "@/component/AgencyWithBranch";

import datepicker from 'vuejs-datepicker';

import {ja} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import HospitalWithDoctor from "@/component/HospitalWithDoctor";
import SaveButtons from "@/component/parts/SaveButtons";
import Device from "@/component/Device";

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: 'PatientManage',
  components: {
    MyBeeHeader,MyBeeSearchBar,
    HospitalWithDoctor,
    datepicker, AgencyWithBranch, SaveButtons,Device
  },
  mixins:[BeeMix],
  data: () => {
    return {
      searchBarPlaceHolder:"カルテID、患者名、処方医、販売店、販売店事業所、登録Noで検索",
      columns: [
        //チェックボックス
        // {type: "selection",   width: 60,   maxWidth: 50,   align: "center", },
        {key:"hospitalName", title: '医療機関名',slot:"hospitalName",headSlot:"hospitalNameHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"karuteNo", title: 'カルテID',slot:"karuteNo",headSlot:"karuteNoHead",resizable: true,align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"name", title: '患者名',slot:"name",headSlot:"nameHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"age", title: '年齢',slot:"age",headSlot:"ageHead",resizable: true,  align: 'center', width:60, cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"gender", title: '性別',slot:"gender",headSlot:"genderHead",resizable: true, width:60,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"doctorName", title: '処方医',slot:"doctorName",headSlot:"doctorNameHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"agencyName", title: '販売店',slot:"agencyName",headSlot:"agencyNameHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"agencyBranchName", title: '販売店事業所',slot:"agencyBranchName",headSlot:"agencyBranchNameHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"created", title: '登録日時',slot:"created",headSlot:"createdHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},width:150},
        {key:"updated", title: '更新日時',slot:"updated",headSlot:"updatedHead",resizable: true,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},width:150,},
        {key:"patientId", title: '登録No',slot:"patientId",headSlot:"patientIdHead",resizable: true,  align: 'center',width:120, cellAlign: 'right',_filterOp: {filterOperation:""},},
        {key:"after", title: ' ',slot:"after",width:70,  align: 'center', cellAlign: 'right',_filterOp: {filterOperation:""},},
      ],
      prefectures: [],
      formData: {
        id: null,
        agencyId: null,
        agencyBranchId:null,
        hospitalId: null,
        doctorId: null,
        lastName: null,
        firstName: null,
        lastNameKana: null,
        firstNameKana: null,
        karuteNo: null,
        gender: 'O',
        prefectureId: null,
        city: null,
        tel: null,
        height: null,
        weight: null,
        birthDay: null,
        branchId: null,
      },
      deviceFormData:{
        gatewaySerialNumber:1,
        gatewayModelNumber:2,
        oxSerialNumber:3,
        oxModelNumber:4,
        blSerialNumber:5,
        blModelNumber:6,
        puSerialNumber:7,
        puModelNumber:8,
      },
      agencyName: null,
      agencyBranchName: null,
      hospitalName: null,
      doctorName: null,
      showAgencyModal: false,
      showHospitalWithDoctorModal: false,
      showDivice:false,
      doctorInfo:{
        doctorId:null,
        doctorName:null,
        hospitalId:null,
        hospitalname:null,
        agencyBranchId:null,
        agencybranchname:null,
        agencyId:null,
        agencyName:null
      },
      editableFlg:false,
      allBranchFlg:false
    }
  },
  mounted() {
    this.getOptions();
    this.setInitHospitalAndProvider();
    if(this.isDoctor()){
      this.hiddenColumn("hospitalName");
    }
    // if(this.isRegistrator()){
    //   this.hiddenColumn("after");
    // }
    // this.isRole(['ADMINISTRATOR','REGISTRATOR','PROVIDER','DOCTOR'])
    this.editableFlg = this.isAdministrator() || this.isRegistrator() || this.isProvider() || this.isDoctor();
  },
  methods: {
    setInitHospitalAndProvider() {
      this.hospitalName = null;
      this.doctorName = null;
      this.formData.hospitalId = null;
      this.formData.doctorId = null;

      this.agencyName = null;
      this.agencyBranchName = null;
      this.formData.agencyId = null;
      this.formData.agencyBranchId = null;

      if(this.isDoctor() || this.isProvider()){
        this.axios.get(`/api/patient/doctorInfo`)
            .then(response => {
              if (this.isDoctor()) {
                let doctorInfo = response.data.result["doctorInfo"];
                this.hospitalName = doctorInfo.hospitalName;
                this.doctorName = doctorInfo.doctorName;
                this.formData.hospitalId = doctorInfo.hospitalId;
                this.formData.doctorId = doctorInfo.doctorId;
              }

              let agencyBranch = response.data.result["agencyBranch"];
              this.agencyName = agencyBranch.agencyName;
              this.agencyBranchName = agencyBranch.agencyBranchName;
              this.formData.agencyId = agencyBranch.agencyId;
              this.formData.agencyBranchId = agencyBranch.agencyBranchId;
            }).catch(error => {
        }).finally(() => {});
      // } else if (this.isProvider()) {
      //   this.axios.get(`/api/patient/doctorInfo`, {}, {headers: {'Content-Type': 'application/json'}})
      //       .then(response => {
      //         let agencyBranch = response.data.result["agencyBranch"];
      //
      //         this.agencyName = agencyBranch.agencyName;
      //         this.agencyBranchName = agencyBranch.agencyBranchName;
      //         this.formData.agencyId = agencyBranch.agencyId;
      //         this.formData.agencyBranchId = agencyBranch.agencyBranchId;
      //
      //       }).catch(error => {
      //   }).finally(() => {
      //       });
      }
    },
    setDoctorInfo() {
      if (this.isDoctor()) {
        // this.agencyName = this.doctorInfo.agencyName;
        // this.agencyBranchName = this.doctorInfo.agencyBranchName;
        this.hospitalName = this.doctorInfo.hospitalName;
        this.doctorName = this.doctorInfo.doctorName;

        // this.formData.agencyId = this.doctorInfo.agencyId;
        // this.formData.agencyBranchId = this.doctorInfo.agencyBranchId;
        this.formData.hospitalId = this.doctorInfo.hospitalId;
        this.formData.doctorId = this.doctorInfo.doctorId;
      }
    },
    setProviderInfo() {
      if (this.isProvider()) {
        this.agencyName = this.doctorInfo.agencyName;
        this.agencyBranchName = this.doctorInfo.agencyBranchName;
        // this.hospitalName = this.doctorInfo.hospitalName;
        // this.doctorName = this.doctorInfo.doctorName;

        this.formData.agencyId = this.doctorInfo.agencyId;
        this.formData.agencyBranchId = this.doctorInfo.agencyBranchId;
        // this.formData.hospitalId = this.doctorInfo.hospitalId;
        // this.formData.doctorId = this.doctorInfo.doctorId;
      }
    },
    toggleAllBranch(){
      this.allBranchFlg = !this.allBranchFlg;
      this.goodPaging()
    },
    showDiviceClick: function () {
      this.mappingInfo();
      this.showDivice = true;
    },
    deviceSelectSave() {
      this.$swal({
        title: "確認",
        text: "設置機器登録情報を更新しますか？",
        buttons: true,
        dangerMode: true,
      }).then((swalResult) => {
        if (swalResult) {
          var serials = [this.deviceFormData.gatewaySerialNumber,
            this.deviceFormData.oxSerialNumber,
            this.deviceFormData.puSerialNumber,
            this.deviceFormData.blSerialNumber].filter(i => i);

          this.axios.post('/api/device/mapping', {patientId: this.formData.id, serialNumbers: serials},
              {headers: {'Content-Type': 'application/json'}})
              .then(response => {
                this.showDivice = false;
              }).catch(error => {
          })
              .finally(() => {
                    this.isLoading = false;
                  }
              );
        }
      });
    },
    hospitalWithDcotorSelect: function () {
      // if (this.isDoctor()) {
      //   return;
      // }
      this.showHospitalWithDoctorModal = true;
    },
    setHospitalWithDoctor: function (row) {
      this.formData.hospitalId = row.hospitalId;
      this.formData.doctorId = row.doctorId;

      this.hospitalName = row.hospitalName;
      this.doctorName = row.doctorName;
      this.showHospitalWithDoctorModal = false;
    },
    agencyBranchSelect: function () {
      // if(this.isProvider()){
      //     return;
      // }
      this.showAgencyModal = true;
    },
    deviceSelect(row) {

      if (row.deviceKind == 'GW') {
        if (this.deviceFormData.gwId != null) {
          this.$swal("変更するには既存の機器を解除してください。");
          return;
        }
        this.deviceFormData.gwId = null;
        this.deviceFormData.gatewaySerialNumber = row.serialNumber;
        this.deviceFormData.gatewayModelNumber = row.originModelNumber;
      } else if (row.deviceKind == 'OX') {
        if (this.deviceFormData.oxId != null) {
          this.$swal("変更するには既存の機器を解除してください。");
          return;
        }
        this.deviceFormData.oxId = null;
        this.deviceFormData.oxSerialNumber = row.serialNumber;
        this.deviceFormData.oxModelNumber = row.originModelNumber;
      } else if (row.deviceKind == 'PU') {
        if (this.deviceFormData.puId != null) {
          this.$swal("変更するには既存の機器を解除してください。");
          return;
        }
        this.deviceFormData.puId = null;
        this.deviceFormData.puSerialNumber = row.serialNumber;
        this.deviceFormData.puModelNumber = row.originModelNumber;
      } else if (row.deviceKind == 'BL') {
        if (this.deviceFormData.blId != null) {
          this.$swal("変更するには既存の機器を解除してください。");
          return;
        }
        this.deviceFormData.blId = null;
        this.deviceFormData.blSerialNumber = row.serialNumber;
        this.deviceFormData.blModelNumber = row.originModelNumber;
      }
    },
    mappingInfo() {
      this.axios.get('/api/patient/mapping_device/' + this.formData.id).then(response => {
        if (response.data.result) {
          this.deviceFormData = response.data.result;
        }
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);

    },
    deviceClear: function (type) {
      this.$swal({
        title: "確認",
        text: "設置機器を解除しますか？",
        buttons: true,
        dangerMode: true,
      }).then((swalResult) => {
        if (swalResult) {
          let serialNumber = null;
          if (type == 'gateway') {
            serialNumber = this.deviceFormData.gatewaySerialNumber
          } else if (type == 'ox') {
            serialNumber =  this.deviceFormData.oxSerialNumber;
          } else if (type == 'pu') {
            serialNumber = this.deviceFormData.puSerialNumber;
          } else if (type == 'bl') {
            serialNumber = this.deviceFormData.blSerialNumber;
          }

          this.axios.post('/api/app/device/unMapping', {serialNumbers:[serialNumber]},{headers: {'Content-Type': 'application/json'}})
              .then(response => {

                if (type == 'gateway') {
                  this.deviceFormData.gwId = null;
                  this.deviceFormData.gatewaySerialNumber = null;
                  this.deviceFormData.gatewayModelNumber = null;
                } else if (type == 'ox') {
                  this.deviceFormData.oxId = null;
                  this.deviceFormData.oxSerialNumber = null;
                  this.deviceFormData.oxModelNumber = null;
                } else if (type == 'pu') {
                  this.deviceFormData.puId = null;
                  this.deviceFormData.puSerialNumber = null;
                  this.deviceFormData.puModelNumber = null;
                } else if (type == 'bl') {
                  this.deviceFormData.blId = null;
                  this.deviceFormData.blSerialNumber = null;
                  this.deviceFormData.blModelNumber = null;
                }
              }).finally(() => {
                    this.isLoading = false;
                  }
              );
        }
      });



    },
    setAgencyWithBranch: function (row) {
      console.log("==================setAgencyWithBranch", row);
      this.formData.agencyId = row.agencyId;
      this.formData.agencyBranchId = row.agencyBranchId;

      this.agencyName = row.agencyName;
      this.agencyBranchName = row.agencyBranchName;
      this.showAgencyModal = false;
    },
    formInit: function () {
      this.formData = {gender: 'O'};
      this.agencyName = null;
      this.agencyBranchName = null;
      this.hospitalName = null;
      this.doctorName = null;
      this.setInitHospitalAndProvider();
    },
    updateUser(row) {
      this.axios.get(`/api/patient/${row.patientId}`).then(response => {
        this.formData = Object.assign({}, response.data.result);
        this.hospitalName = response.data.result.hospitalName;
        this.doctorName = response.data.result.doctorName;

        this.agencyBranchName = response.data.result.agencyBranchName;
        this.agencyName = response.data.result.agencyName;
        this.isCollapsed = false;

      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    save: function () {
      if (document.querySelector("#birthDay").value != '' && this.formData.birthDay != document.querySelector("#birthDay").value) {
        this.$swal("生年月日の日付が不正です");
        return
      }
      this.axios.post('/api/patient/save', this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
        if (response.data.result) {
          this.formData.id = response.data.result;
        }
        this.goodPaging();
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    idClick: function (row) {
      this.$router.push("/pages/patient/summary/" + row.id);
    },
    createPatient: function () {
    }, goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;

      this.axios.post('/api/patient/search/fromPatientManage', {
        pagingForm: this.pagingParams,
        archiveFlg: false,
        allBranchFlg: this.allBranchFlg
      }, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }, getOptions: function () {
      this.axios.post('/api/hospital/getHospitalDoctorOptions', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.prefectures = response.data.result.prefectures;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }
  }
  , watch: {
    'formData.birthDay': function () {
      if (this.formData.birthDay) {
        this.formData.birthDay = moment(this.formData.birthDay).format('YYYY/MM/DD');
        // let tagVal = document.querySelector("#birthDay").value;
      }
    },
    isCollapsed:function(newValue){
      if(newValue){
        this.formInit();
      }
    }
  }, computed: {
    ja() {
      return ja;
    }
  },
}
</script>
